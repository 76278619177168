import React, { useState , useRef, useEffect } from "react";
import ReactFitText from 'react-fittext'
import './FitPanel.css'
import FBLogoOutline from '../Assets/Icons/facebookIconOutline.svg'
import FBLogo from '../Assets/Icons/facebookIcon.svg'

import {setEventInfo} from '../Actions'
import {showEventInfo} from '../Actions'
import {useDispatch} from 'react-redux'

function FitPanel(props) {

  const [FBhover, setFBHover] = useState(false);
  const FBsize = FBhover? "1.8em" : "1.7em";
  const [animateE , setAnimateE] = useState(false)

  const dispatch = useDispatch();

  const ref1 = useRef();
  
  function getRandomInt(max) {
    return (Math.floor(Math.random() * Math.floor(max)) - max/2);
  }

  
 
  // const eventStyle = {transform:  "translateX(" + getRandomInt(10) + 'vw)' + 'rotateZ(' + getRandomInt(15) + 'deg)' , transition: 'all 1s ease'}
  // style = {{transform: 'translateX('+useEffect(()=>{getRandomInt(20)})+'em)'}}

  // function assignStyle(x){
  //   return( ref1.current.style.transform = "translateX(" + getRandomInt(x) + 'em)' + " rotateZ(" + getRandomInt(x) + 'deg)' + " skew(" + getRandomInt(x*0.2) + 'deg)' 
     
  //     );
  // }

  // useEffect(()=>{
  //   assignStyle(getRandomInt(30));
  // },[animateE])

  return (
    <div ref = {ref1} id = 'FitPanel' style = {{backgroundColor: props.color[1], transform: 'skew(' + props.skew+ ')'}} >
    <div id = 'front' style = {{backgroundColor: props.color[0]}}>
      {/* <button id ='datum'>{props.datum.split('-')[0]}&nbsp;{props.datum.split('-')[1]}&nbsp;{props.datum.split('-')[2]}</button> */}

          <ReactFitText compressor={props.title.length*0.08} maxFontSize = {150}>
            <button id = 'titel' onClick = {()=> { }} >
            {props.title}
            </button>
          </ReactFitText>

          {/* <a href= {props.fbLink} id ='FBlink' onMouseEnter = {()=> setFBHover(1)}  onMouseLeave = {()=> setFBHover(0) } >
            <img src = {FBLogoOutline} alt = 'FB' style ={{width: FBsize}}></img> 
          </a> */}
          
      </div>  
    </div>
  );
}

export default FitPanel

// style = {{transform:  "translateX(" + getRandomInt(10) + 'vw)' + 'rotateZ(' + getRandomInt(15) + 'deg)' , transition: 'all 1s ease'}}
// console.log(window.getComputedStyle(ref1.current).getPropertyValue("grid-row"));