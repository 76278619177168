export function setEventInfo(str){
    return{
        type: "SET_EVENT_INFO",
        payLoad: str
    }
}

export function showEventInfo(str){
    return{
        type: "SHOW_EVENT_INFO",
        payLoad: str
    }
}

export default showEventInfo