import React, { useState, useEffect } from "react";
import tekst from "../Assets/Tekst/teksten.json";
import TekstPanel from "../Components/TekstPanel";
import "./Contact.css";
import { Textfit } from "react-textfit";
import ContactPanel from "../Components/ContactPanel";
import GifPlayer from "react-gif-player";
import kroewGif from "../Assets/Gifs/07-CONTACT.gif";

function Contact(props) {
  const [showGif, setShowGif] = useState(false);
  const [showContent, setShowContent] = useState(false);
  var [imageLoad, setImageLoad] = useState(0);

  function closeGif() {
    const timer = setTimeout(() => {
      setShowGif(false);
      setShowContent(true);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div id="pageWrapper">
      <GifPlayer
        gif={kroewGif}
        onLoad={() => setShowGif(true)}
        autoplay={true}
        style={{
          transform: showGif ? "scale(1)" : "scale(0)",
          transition: "all 1.2s ease",
        }}
      ></GifPlayer>
      <div
        id="contact"
        style={{
          opacity: showContent ? "1" : "0",
          transition: "opacity 1s ease 0.5s",
        }}
      >
        <ContactPanel
          type="contact"
          title={["contact", "gegevens"]}
          adres={[
            "Algemene vragen:",
            "sjef@perexpressie.nl",
            "",
            "Bandbunker / de Kabine:",
            "",
            "",
            "tim@perexpressie.nl",
            "Bestuur:",
            "",
            "bestuur@perexpressie.nl",
          ]}
          color={["var(--purple", "var(--sea)"]}
          rotate={["-5deg", "0deg"]}
        ></ContactPanel>

        <ContactPanel
          title={["adres", "gegevens"]}
          adres={[
            "Perron Per Expressie",
            "Soesterweg",
            "334",
            "3812",
            "BH",
            "Amersfoort",
            "Bandbunker / De Kabine",
            "Soesterweg",
            "334B",
            "3812",
            "BH",
            "Amersfoort",
          ]}
          color={["var(--sea", "var(--purple)"]}
          rotate={["-1deg", "0deg"]}
          order="1"
          extra={true}
        ></ContactPanel>

        <ContactPanel
          title={["factuur", "gegevens"]}
          factuur={true}
          adres={[
            "Stichting Per Expressie",
            "Stovestraat",
            "17",
            "3811",
            "KA",
            "Amersfoort",
            "Tnv M BOSCH",
          ]}
          color={["var(--purple", "var(--sea)"]}
          rotate={["2deg", "-0deg"]}
          // order="1"
        ></ContactPanel>

        <div id="maps">
          <div
            style={{
              minHeight: "300px",
              height: "100%",
              width: "100%",
              position: "relative",
              // top: "30px",
              // border: "2px solid #eee",
              display: "inline-block",
              overflow: "hidden",
            }}
          >
            <iframe
              onLoad={() => closeGif()}
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;hl=nl&amp;q=per%20expressie+(Per%20Expressie)&amp;t=h&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>

            {/* <iframe
              title="map"
              style={{
                position: "relative",
                top: "-60px",
                border: "none",
                minHeight: "300px",
              }}
              id="maps"
              onLoad={() => closeGif()}
              src="https://www.google.com/maps/d/embed?mid=19jI-JtItHCQoVnelO3dKTaa7KC-UsTi1&amp;t=j&z=15&amp"
              width="100%"
              height="100%"
            ></iframe> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

{
  /* <div id = 'contactGegevens'>
                <div id = 'title'> 
                    <Textfit mode = 'single'>
                    <h1>contact<br/>gegevens</h1>
                    </Textfit>
                </div>

                <div id = 'geg'>
                    <Textfit mode = 'single'>
                        <h4>
                            Per Expressie <br/>
                            Soesteregweg&nbsp;344<br/>
                            Amersfoort<br/><br/>
                            033-1111111
                        </h4>
                    </Textfit>
                    
                    
                </div>
            </div> */
}

{
  /* <div id = 'post'>
                <div id = 'contactGegevensShad'></div>
                <div id = 'contactGegevens'>
                    <h1>contact<br/>gegevens</h1>
                    <h4>
                        Per Expressie <br/>
                        Soesteregweg 344 <br/>
                        Amersfoort <br/><br/>
                        033-1111111
                    </h4>
                </div>
                
                
            </div>
            <div id = 'factuur'>
                <div id = 'factuurShad'></div>
                <div id = 'factuurGegevens'>
                    
                    <h4>
                        Per Expressie <br/>
                        Soesteregweg 344 <br/>
                        Amersfoort <br/><br/>
                        033-1111111
                    </h4>
                    <h1><Textfit mode = 'single'>factuur<br/>gegevens</Textfit></h1>
                </div>
                
                
            </div>
            <div id = 'maps'>
                
            <iframe title = 'Per Expressie' style = {{width:"100%" , height:"100%"}} src="https://maps.google.com/maps?width=100%&height=600&hl=nl&coord=52.1569632,5.3611646&q=Soesteregweg%20334+(Per%20Expressie)&ie=UTF8&t=p&z=15&iwloc=A&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.mapsdirections.info/nl/cirkel-straal-kaart/">Straal op kaart Google</a></iframe><br />


            </div> */
}
