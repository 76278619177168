import React, { useState, useEffect } from "react";
import "./Bandbunker.css";
import Info from "../Components/Info";
import TekstPanel from "../Components/TekstPanel";
import tekst from "../Assets/Tekst/teksten.json";
import kroew from "../Assets/Pics/kroew.jpg";
import GifPlayer from "react-gif-player";
import kroewGif from "../Assets/Gifs/05-BANDBUNKER.gif";

function Bandbunker(props) {
  const [showGif, setShowGif] = useState(false);
  const [gifSize, setGifSize] = useState(1);
  const [showContent, setShowContent] = useState(false);
  var [imageLoad, setImageLoad] = useState(0);

  function closeGif() {
    const timer = setTimeout(() => {
      setGifSize(0);
      setShowContent(true);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div className="pageWrapper">
      <GifPlayer
        gif={kroewGif}
        autoplay={true}
        onLoad={() => setShowGif(true)}
        style={{
          transition: "all 0.3s ease",
          transform: "scale(" + gifSize + ")",
        }}
      ></GifPlayer>
      <div
        className="bandbunker"
        style={{
          opacity: showContent ? "1" : "0",
          transition: "opacity 1s ease 0.5s",
        }}
      >
        <div id="tekst">
          <TekstPanel
            title="DE BANDBUNKER"
            tekst2={props.tekst}
            color={["var(--pink)", "var(--green)"]}
          ></TekstPanel>
        </div>
        <Info
          specs="ruimte1"
          link="http://www.bandbunker.nl/product/ruimte-1/"
          bgColor="#4CA9AF"
          title={"ruimte 1"}
          skew={["0deg", "2deg"]}
          shift={["-5px", "-5px"]}
          translate={["3px"]}
          color={["var(--pink)", "var(--sky)", "var(--green)"]}
        ></Info>
        {/* <img id="omni1" src={kroew} alt="foto"></img> */}
        <iframe
          id="omni1"
          style={{
            border: "2px solid white",
            minHeight: "30vh",
          }}
          onLoad={() => setImageLoad(imageLoad + 1)}
          title="ruimte1"
          height="100%"
          width="100%"
          allowfullscreen="true"
          src="https://momento360.com/e/u/89ce88d44f6b42d28d4e92aa5110913d?utm_campaign=embed&utm_source=other&heading=-367.5208843730595&pitch=-8.696836038147183&field-of-view=75"
        ></iframe>
        <Info
          specs="ruimte2"
          link="http://www.bandbunker.nl/product/ruimte-2/"
          bgColor="#DAA0C8"
          title="ruimte 2"
          skew={["0deg", "3deg"]}
          shift={["5px", "-5px"]}
          translate={["3px"]}
          color={["var(--green)", "var(--sky)", "var(--pink)"]}
        ></Info>
        <iframe
          id="omni2"
          style={{ border: "2px solid white", minHeight: "30vh" }}
          onLoad={() => setImageLoad(imageLoad + 1)}
          title="ruimte2"
          height="100%"
          width="100%"
          allowfullscreen="true"
          src="https://momento360.com/e/u/32bb1a51912a46af8373bace08ad1332?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75"
        ></iframe>
        <Info
          specs="gear"
          link="http://www.bandbunker.nl/product/dj-ruimte/"
          bgColor="#DAA0C8"
          title="DJ ruimte"
          skew={["0deg", "3deg"]}
          shift={["5px", "-5px"]}
          translate={["3px"]}
          color={["var(--pink)", "var(--sky)", "var(--green)"]}
        ></Info>
        <iframe
          id="omni3"
          style={{ border: "2px solid white", minHeight: "30vh" }}
          onLoad={() => setImageLoad(imageLoad + 1)}
          title="DJ ruimte"
          height="100%"
          width="100%"
          allowfullscreen="true"
          src="https://momento360.com/e/u/f01dce0d41724157b4b788ad95131ffc?utm_campaign=embed&utm_source=other&heading=7.180314654524405&pitch=-9.706227471299751&field-of-view=75"
        ></iframe>
      </div>
      {imageLoad == 1 ? closeGif() : "null"}
    </div>
  );
}

export default Bandbunker;
