import React, { useState } from 'react'
import {a} from 'react-spring'
import './menuList.css'
import Menuitem from './menuItem'

const pages = [ {name: "AGENDA", path: "./agenda" }, {name: "KROEW", path: "./kroew"} , {name: "MAKERS", path: "./makers"} , {name: "DE&nbsp;BANDBUNKER", path: "./bandbunker"} , {name: "DE&nbsp;KABINE", path: "./kabine"} , {name: "OVER&nbsp;ONS", path: "./over ons"} , {name: "CONTACT", path: "./contact"}]

function Menulist(props){

    const menuItems = pages.map((page) => <Menuitem mode = {props.mode} key = {page.name} page = {page}></Menuitem> )
        
    return(
        <a.ul id = "menuList" >
            {menuItems}
        </a.ul>
    );
}

export default Menulist