import React, { useState, useEffect } from "react";
import tekst from "../Assets/Tekst/teksten.json";

import TekstPanel from "../Components/TekstPanel";
import "./Over.css";
import GifPlayer from "react-gif-player";
import overGif from "../Assets/Gifs/overons-freeze.gif";
import Bio from "../Components/Bio";

import wouter from "../Assets/Pics/woutervanopbergen.png";
import tim from "../Assets/Pics/tim.jpeg";

function Over(props) {
  const [showGif, setShowGif] = useState(false);
  const [gifSize, setGifSize] = useState(0.7);
  const [showContent, setShowContent] = useState(false);
  var [imageLoad, setImageLoad] = useState(0);

  function closeGif() {
    const timer = setTimeout(() => {
      setGifSize(0);
      setShowContent(true);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div className="pageWrapper">
      <GifPlayer
        id="overGif"
        onLoad={() => setShowGif(true)}
        gif={overGif}
        autoplay={true}
        style={{
          transform: "scale(" + gifSize + ") translate(-10vw, -0em)",
          transition: "all 0.5S ease",
        }}
      ></GifPlayer>
      <div
        id="over"
        style={{
          opacity: showContent ? "1" : "0",
          transition: "opacity 1s ease",
        }}
      >
        <div id="tekst">
          <TekstPanel
            title="OVER ONS"
            tekst2={props.tekst}
            color={["var(--pink)", "var(--purple)"]}
          ></TekstPanel>
        </div>
        {/* <div id="tim">
          <div id="foto"></div>
          <div id="intro">
            <h1>TIM</h1>
            <p>
              Diederik is Sjefsjef van het Perron. Hij is algemeen
              contactpersoon voor Per Expressie en daarbij nog een aardige kerel
              ook. Hij houdt van Banh Mi en heeft een cavia als huisdier.
              Groetjes!
            </p>
          </div>
        </div>
        <div id="diederik">
          <div id="foto"></div>
          <div id="intro">
            <h1>DIEDERIK</h1>
            <p>
              Diederik is Sjefsjef van het Perron. Hij is algemeen
              contactpersoon voor Per Expressie en daarbij nog een aardige kerel
              ook. Hij houdt van Banh Mi en heeft een cavia als huisdier.
              Groetjes!
            </p>
          </div>
        </div> */}
        <Bio
          justify="left"
          pic={tim}
          tekst={props.bio1}
          w="50%"
          telefoon="0611779291"
        ></Bio>
        <Bio
          justify="flex-end"
          pic={wouter}
          tekst={props.bio2}
          w="35%"
          telefoon="0610531611"
        ></Bio>
      </div>
      {closeGif()}
    </div>
  );
}

export default Over;
