import React, { useState, Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import logo from "./logo.svg";
import "./App.css";

import Navigatie from "./Components/Navigatie";
import Agenda from "./Pages/Agenda";

import Makers from "./Pages/Makers";
import Bandbunker from "./Pages/Bandbunker";
import kabine from "./Pages/Kabine";
import Kabine from "./Pages/Kabine";
import Over from "./Pages/Over";
import Contact from "./Pages/Contact";
import Kroew from "./Pages/Kroew";
import NavBar from "./Components/NavBar";
import Shader from "./Pages/shader";
import HelloBlue from "./Pages/shader";
import { Surface } from "gl-react-dom"; // for gl-React DOM
import fbLogo from "./Assets/Icons/fb_logo.svg";
import instaLogo from "./Assets/Icons/insta_logo.svg";
import EventInfo from "./Components/EventInfo";
import NavBarDesktopX from "./Components/NavBarDesktopX";
import Background from "./Components/bg";
import menuList from "./Components/Menulist";
import { a, useTransition } from "react-spring";
import NavigatieBar from "./Components/NavigatieBar";
import Menumobiel from "./Components/Menumobiel";

// Kontent deps
import { DeliveryClient, KontentDelivery } from "@kentico/kontent-delivery";

const client = new DeliveryClient({
  projectId: "0d321c03-e35e-003b-b9d8-1bfa29551065",
});

function App() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [sw, setSW] = useState(window.innerWidth);
  window.addEventListener("resize", function () {
    // console.log(window.innerWidth);
    setSW(window.innerWidth);
  });

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // Kontent setup
  const [loaded, setLoaded] = useState(false);
  const [tekst, setTekst] = useState([]);
  const [limit, setLimit] = useState(true);

  function fetchTekst() {
    client
      .items()
      .type("teksten")
      // .limitParameter(limit ? 3 : 100)
      // .orderParameter("system.last_modified[desc]")
      // .orderParameter("elements.datumx[asc]")
      .elementsParameter()
      .toObservable()
      .subscribe((response) => {
        setTekst(response.items);
        setLoaded(true);
        // console.log(events);
      });
  }

  useEffect(() => {
    console.log("fetch...");
    fetchTekst();
  }, [loaded]);

  const teksten = tekst[0];

  return (
    <Router>
      <div className="App">
        {/* {window.confirm(
          "Karig hè, die Corona! Nu zit je thuis uit je neus te vreten en te wachten totdat je weer aan de bak mag. Erger nog; Het een streep door onze evenementen! We doen ons best om onze feestjes, expo’s en andere gekkigheid zo snel mogelijk weer terug te brengen. Maar ondertussen: heb jij een tof idee, die wél uitgevoerd kan worden? Of heb je een plek nodig waar je creatief kan zijn? Stuur Sjefsjef Diederik even een appje! Hou je taai! #StayStrong"
        )} */}
        <Redirect from="/" exact={true} to="/agenda" />
        <Background></Background>

        {sw > 1000 ? (
          <NavBarDesktopX></NavBarDesktopX>
        ) : (
          <NavigatieBar></NavigatieBar>
        )}

        <Switch>
          <Route path="/agenda" exact={true} component={Agenda}></Route>
          <Route
            path="/kroew"
            exact={true}
            render={(props) => (
              <Kroew
                {...props}
                isAuthed={true}
                tekst={teksten != null ? teksten.kroew.value : "null"}
              />
            )}
          />
          <Route
            path="/makers"
            exact={true}
            render={(props) => (
              <Makers
                {...props}
                isAuthed={true}
                tekst={teksten != null ? teksten.makers.value : "null"}
              />
            )}
          />
          <Route
            path="/bandbunker"
            exact={true}
            render={(props) => (
              <Bandbunker
                {...props}
                isAuthed={true}
                tekst={teksten != null ? teksten.de_bandbunker.value : "null"}
              />
            )}
          />
          <Route
            path="/kabine"
            exact={true}
            render={(props) => (
              <Kabine
                {...props}
                isAuthed={true}
                tekst={teksten != null ? teksten.de_kabine.value : "null"}
              />
            )}
          />
          <Route
            path="/over ons"
            exact={true}
            render={(props) => (
              <Over
                {...props}
                isAuthed={true}
                tekst={teksten != null ? teksten.over_ons.value : "null"}
                bio1={teksten != null ? teksten.bio1.value : "null"}
                bio2={teksten != null ? teksten.bio2.value : "null"}
              />
            )}
          />
          <Route
            path="/contact"
            exact={true}
            render={(props) => (
              <Contact
                {...props}
                isAuthed={true}
                tekst={teksten != null ? teksten.contact.value : "null"}
              />
            )}
          />
          <Route path="/menu" exact={true} component={Menumobiel}></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
