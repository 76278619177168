import React, { useState, useEffect } from "react";
import Agenda from "../Assets/SVG/Vectors/Group 6.svg";
import Kroew from "../Assets/SVG/Vectors/Group 7.svg";
import Makers from "../Assets/SVG/Vectors/Group 8.svg";
import Bandbunker from "../Assets/SVG/Vectors/Group 9.svg";
import Kabine from "../Assets/SVG/Vectors/Group 10.svg";
import Over from "../Assets/SVG/Vectors/Group 11.svg";
import Contact from "../Assets/SVG/Vectors/Group 12.svg";
import menu from "../Assets/SVG/Group 13.svg";
import { NavLink, Link } from "react-router-dom";
import "./Menumobiel.css";
import { useSpring, animated, config } from "react-spring";

export default function Menumobiel(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const delayVal = 50;
  //   useEffect(() => {
  //     setShowMenu(true);
  //   }, [trigger]);

  const agendaStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 0%",
    number: props.ani ? "33" : "0",

    config: config.wobbly,
    delay: delayVal * 2,
  });

  const kroewStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 0%",
    number: props.ani ? "33" : "0",
    config: config.wobbly,
    delay: delayVal * 1,
  });

  const makersStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 50%",
    number: props.ani ? "33" : "0",
    config: config.wobbly,
    delay: delayVal * 4,
  });

  const bandbunkerStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 0%",
    number: props.ani ? "33" : "0",
    config: config.wobbly,
    delay: delayVal * 1,
  });

  const kabineStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 0%",
    number: props.ani ? "33" : "0",
    config: config.wobbly,
    delay: delayVal * 2,
  });

  const overStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 60%",
    number: props.ani ? "33" : "0",
    config: config.gentle,
    delay: delayVal * 1,
  });

  const contactStijl = useSpring({
    transform: props.ani ? "scale(1)" : "scale(0)",
    transformOrigin: "50% 0%",
    number: props.ani ? "33" : "0",
    config: config.wobbly,
    delay: delayVal * 0,
  });

  const style = {
    marginBottom: "-7.8vh",
    marginRight: "-15px",
    width: "100vw",
    height: "18%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    // border: "solid grey 5px",
    // opacity: 0.5,
  };

  const testStyle = useSpring({
    number: props.ani ? 3 : 0,
    config: config.wobbly,
  });
  return (
    <div id="menuWrapper">
      <div id="menuMobiel" style={{ height: `${window.innerHeight}px` }}>
        {/* {console.log(testStyle.number.value)} */}
        <NavLink to={"/agenda"} style={style}>
          <animated.img
            src={Agenda}
            alt="agenda"
            style={agendaStijl}
          ></animated.img>
        </NavLink>
        <NavLink to={"/kroew"} style={style}>
          <animated.img
            src={Kroew}
            alt="agenda"
            style={kroewStijl}
          ></animated.img>
        </NavLink>
        <NavLink to={"/makers"} style={style}>
          <animated.img
            src={Makers}
            alt="agenda"
            style={makersStijl}
          ></animated.img>
        </NavLink>
        <NavLink to={"/bandbunker"} style={style}>
          <animated.img
            src={Bandbunker}
            alt="agenda"
            style={bandbunkerStijl}
          ></animated.img>
        </NavLink>
        <NavLink to={"/kabine"} style={style}>
          <animated.img
            src={Kabine}
            alt="agenda"
            style={kabineStijl}
          ></animated.img>
        </NavLink>
        <NavLink to={"/over ons"} style={style}>
          <animated.img
            src={Over}
            alt="agenda"
            style={overStijl}
          ></animated.img>
        </NavLink>
        <NavLink to={"/contact"} style={style}>
          <animated.img
            src={Contact}
            alt="agenda"
            style={contactStijl}
          ></animated.img>
        </NavLink>

        {/* <img src = {menu} alt = 'menu'></img> */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          href="http://www.w3.org/1999/xlink"
          viewBox="0 0 375 667"
        >
          <defs>
            <filter
              id="a"
              x="60.873"
              y="85.841"
              width="273.933"
              height="133.345"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="b" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="b" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter
              id="c"
              x="96.588"
              y="164.211"
              width="206.966"
              height="119.836"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="d" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="d" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter
              id="e"
              x="94.643"
              y="231.646"
              width="216.339"
              height="116.416"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="f" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="f" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter
              id="g"
              x="56.673"
              y="293.048"
              width="282.141"
              height="122.815"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="h" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="h" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter
              id="i"
              x="81.961"
              y="363.704"
              width="243.412"
              height="116.678"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="j" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="j" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter
              id="k"
              x="80.938"
              y="428.15"
              width="229.92"
              height="119.553"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="l" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="l" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter
              id="m"
              x="95.113"
              y="492.196"
              width="205.639"
              height="126.881"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="10" dy="10" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="10" result="n" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="n" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <NavLink to={"/agenda"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#a)"
              style={agendaStijl}
            >
              <path
                d="M-1232.077-247.882c1.467,22,11.734,30.8,38.136,32.269s68.938-5.867,86.539-5.867,30.8,0,46.936,5.867,29.335,8.8,35.2-5.867,16.134-55.737-35.2-60.137S-1147-262.55-1174.873-265.483-1233.543-269.883-1232.077-247.882Z"
                transform="translate(1314 389)"
                fill="#bebebe"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>

            <text
              transform="translate(189 157)"
              fill="#fff"
              font-size="33"
              font-family="Arial, Helvetica, sans-serif"
            >
              <tspan x="-41.563" y="0">
                agenda
              </tspan>
            </text>
          </NavLink>
          <NavLink to={"/kroew"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#c)"
              style={kroewStijl}
            >
              <path
                d="M-611.5-204.218c-36.13,1.128-50.132-4.947-60.547,6.336s-13.945,36.539,6.364,41.052,36.155,1.128,54.182,0,43.978,13.539,58.645,9.026,22.566-18.052,13.539-38.361S-575.37-205.346-611.5-204.218Z"
                transform="translate(798 390)"
                fill="#78b4dc"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>
            <animated.text
              transform="translate(189 223)"
              fill="#fff"
              font-size={kroewStijl.number}
              font-family="bebas"
            >
              <tspan x="-37.191" y="0">
                kroew
              </tspan>
            </animated.text>
          </NavLink>
          <NavLink to={"/makers"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#e)"
              style={makersStijl}
            >
              <path
                d="M-1200.312-94.6c-12.151-8.679-14.754-24.3-5.207-31.245s23.433-14.754,51.206-7.811,39.923-3.472,57.282,0,41.659,9.547,39.924,23.433-36.452,37.32-70.3,24.3S-1188.161-85.923-1200.312-94.6Z"
                transform="translate(1327 389)"
                fill="#ddb3d0"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>
            <animated.text
              transform="translate(189 289)"
              fill="#fff"
              font-size={makersStijl.number}
              font-family="bebas"
            >
              <tspan x="-42.306" y="0">
                makers
              </tspan>
            </animated.text>
          </NavLink>
          <NavLink to={"/bandbunker"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#g)"
              style={bandbunkerStijl}
            >
              <path
                d="M-1246.127-36.452c-7.128-15.622,4.155-22.566,17.173-27.773s28.641-6.943,91.13-6.075,59.885-8.679,85.923,0,24.3,20.83,23.433,33.848-39.924,30.377-76.376,19.962-58.15,2.6-91.13,0S-1239-20.83-1246.127-36.452Z"
                transform="translate(1326 388)"
                fill="#a5ceeb"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>
            <animated.text
              transform="translate(189 355)"
              fill="#fff"
              font-size={bandbunkerStijl.number}
              font-family="bebas"
            >
              <tspan x="-86.856" y="0">
                de bandbunker
              </tspan>
            </animated.text>
          </NavLink>
          <NavLink to={"/kabine"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#i)"
              style={kabineStijl}
            >
              <path
                d="M-1226,21.7c1.385,40.792,23.951,30.377,63.875,26.905s75.508-9.547,98.073,0,20.83-11.283,17.358-18.226-11.283-38.188-81.583-31.245S-1227.385-19.094-1226,21.7Z"
                transform="translate(1329 387)"
                fill="#9fabd0"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>
            <animated.text
              transform="translate(189 421)"
              fill="#fff"
              font-size={kabineStijl.number}
              font-family="bebas"
            >
              <tspan x="-54.945" y="0">
                de kabine
              </tspan>
            </animated.text>
          </NavLink>
          <NavLink to={"/over ons"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#k)"
              style={overStijl}
            >
              <path
                d="M2516,383.614c2.31,33.848,26,25.95,50.826,24.822s60.927-5.641,78.979,0,34.976,0,37.233-12.411,0-38.361-20.309-41.746-48.516,2.257-65.44,0S2513.69,349.765,2516,383.614Z"
                transform="translate(-2414 96)"
                fill="#ecbd6b"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>
            <animated.text
              transform="translate(189 487)"
              fill="#fff"
              font-size={overStijl.number}
              font-family="bebas"
            >
              <tspan x="-50.672" y="0">
                over ons
              </tspan>
            </animated.text>
          </NavLink>
          <NavLink to={"/contact"}>
            <animated.g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#m)"
              style={contactStijl}
            >
              <path
                d="M2663.857,447c-7.9,33.645-4.513,25.747-41.746,23.491s-57.542,14.668-68.825,11.283-46.26-34.977-25.95-46.259,54.157-23.694,76.723-15.8S2671.755,413.355,2663.857,447Z"
                transform="translate(-2405 96)"
                fill="#f2c371"
                stroke="#fff"
                stroke-width="2"
              />
            </animated.g>
            <animated.text
              transform="translate(189 553)"
              //   transform="scale(1)"
              fill="#fff"
              font-size={contactStijl.number}
              font-family="bebas"
            >
              <tspan x="-46.002" y="0">
                contact
              </tspan>
            </animated.text>
          </NavLink>
        </svg> */}
      </div>
    </div>
  );
}
