import React, { useState } from "react";
import "./Bio.css";

import pic from "../Assets/Pics/diederik.jpeg";

export default function Bio(props) {
  const [showGegevens, setShowGegevens] = useState(false);

  function createMarkup() {
    // console.log(props.tekst);
    return { __html: props.tekst };
  }

  return (
    <div id="bio" style={{ justifySelf: props.justify }}>
      <img style={{ width: props.w }} src={props.pic} alt="pic"></img>
      <div dangerouslySetInnerHTML={createMarkup()} style={{ height: "90%" }}>
        {/* <h1>TIM</h1>
        <p>
          Diederik is Sjefsjef van het Perron. Hij is algemeen contactpersoon
          voor Per Expressie en daarbij nog een aardige kerel ook. Hij houdt van
          Banh Mi en heeft een cavia als huisdier. Groetjes!
        </p> */}
      </div>
      <button onClick={() => setShowGegevens(!showGegevens)}>BEL</button>
      <div id="gegevens" style={{ display: showGegevens ? "flex" : "none" }}>
        {props.telefoon}
      </div>
    </div>
  );
}
