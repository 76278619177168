// React deps
import React, { useState, useEffect } from "react";

// Redux deps
import { useSelector, useDispatch } from "react-redux";
import showEventInfo from "../Actions";

// Kontent deps
import { DeliveryClient, KontentDelivery } from "@kentico/kontent-delivery";

// Styling and Components
import "./Agenda.css";
import EventPanel from "../Components/EventPanel";
import laadMeer from "../Assets/Icons/laadO.svg";
import laadMinder from "../Assets/Icons/laadMinder.svg";
import gemist from "../Assets/Icons/gemist.svg";

// GL-react
import HelloBlue from "../Pages/shader";
import { Surface } from "gl-react-dom"; // for React DOM

import GifPlayer from "react-gif-player";
import agendaGif from "../Assets/Gifs/agenda-freeze.gif";

// import ShadertoyReact from "shadertoy-react";
import shadeBG from "../Assets/Shaders/fsBG.js";
import shadeInfo from "../Assets/Shaders/fsInfo.js";
import EventInfo from "../Components/EventInfo";

const client = new DeliveryClient({
  projectId: "0d321c03-e35e-003b-b9d8-1bfa29551065",
});

let currentDate = new Date();
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MEI",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OKT",
  "NOV",
  "DEC",
];
var dates = {
  compare: function (a, b) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).

    return isFinite(a) && isFinite(b) ? (a > b) - (a < b) : NaN;
  },
};
function Agenda() {
  // Redux setup
  const eventInfo = useSelector((state) => state.setEventInfo);
  const eventInfoEnabled = useSelector((state) => state.showEventInfo);
  const dispatch = useDispatch();

  // Kontent setup
  const [loaded, setLoaded] = useState(false);
  const [events, setEvents] = useState([]);
  const [limit, setLimit] = useState(false);
  const [status, setStatus] = useState([]);
  const [count, setCount] = useState(0);

  function fetchEvents() {
    // console.log("FEEEEETCH");
    // console.log("haaaa = " + status.filter(isPast).length);
    client
      .items()
      .type("event")
      .limitParameter(limit ? 3 + status.filter(isPast).length : 100)
      // .orderParameter("system.last_modified[desc]")
      .orderParameter("elements.datumx[asc]")
      .elementsParameter()
      .toObservable()
      .subscribe((response) => {
        setEvents(response.items);
        setLoaded(true);
        count === 1 ? setLimit(true) : console.log("done");
      });
  }

  function isPast(val) {
    return val < 0;
  }

  useEffect(() => {
    fetchEvents();

    setStatus(
      events.map((event, index) => {
        return dates.compare(event.zichtbaar_tot.value, currentDate);
      })
    );
    setCount(count + 1);
    console.log(count);
  }, [loaded, limit]);
  // console.log(status);
  // Show content
  const [load, setLoad] = useState(true);
  const [showGif, setShowGif] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [gifSize, setGifSize] = useState(1);
  const [showThreshold, setShowThreshold] = useState(false);

  function closeGif() {
    const timer = setTimeout(() => {
      setGifSize(0);
      setShowContent(true);
    }, 0);
    return () => clearTimeout(timer);
  }

  var timer = 0;

  if (loaded) {
    function createMarkup() {
      return { __html: eventInfo.info };
    }

    return (
      <div
        className="pageWrapper"
        onClick={() =>
          eventInfoEnabled ? dispatch(showEventInfo(!eventInfoEnabled)) : "null"
        }
      >
        {/* {console.log(`val = ${numPast}`)} */}
        <GifPlayer
          id="agendaGif"
          onLoad={() => setShowGif(true)}
          gif={agendaGif}
          autoplay={true}
          style={{
            transform: "scale(" + { gifSize } + ")",
            transition: "all 2s ease",
          }}
        ></GifPlayer>

        <div
          className="wrapper"
          style={{
            opacity: showContent ? "1" : "0",
            transition: "opacity 1s ease 0.5s",
            // height: `${window.innerHeight}px`,
          }}
        >
          {/* <ShadertoyReact fs  = {shadeBG} style = {{ position: 'fixed' , width: '100vw' ,height: '100vh'}}></ShadertoyReact> */}
          <div id="agenda">
            {/*<button
              style={{
                width: "20%",
                height: "2em",
                alignSelf: "center",
                justifySelf: "flex-end",
                marginTop: "-5em",
                marginBottom: "3em",
                backgroundColor: "orange",
              }}
              onClick={() => setShowThreshold(!showThreshold)}
            >
              DIT HEB JE GEMIST, SUKKEL
            </button> */}
            <img
              id="gemist"
              src={gemist}
              alt="gemist"
              onClick={() => {
                setShowThreshold(!showThreshold);
              }}
            ></img>
            {events.map((event, index) => {
              var even = index % 2 ? true : false;
              
              const date = event.datumx.value;
              const expDate = event.zichtbaar_tot.value;
              let url = "";
              event.foto_s.value[0]
                ? (url = event.foto_s.value[0].url)
                : (url = url);

              const day = date.getDate();
              const month = months[date.getMonth()];
              const year = date.getFullYear();
              const show = dates.compare(expDate, currentDate);
              // if (show < 0) {
              //   // setNumPast(numPast + 1);
              //   console.log("spatie");
              // }
              // show < 0 ? console.log("jo") : console.log("tjus");
              // console.log(numPast);

              return (
                <EventPanel
                  key={event.titel.value}
                  title={event.titel.value}
                  datum={`${day} ${month} ${year}`}
                  locatie={event.locatie.value}
                  fbLink={event.facebookurl.value}
                  info={event.info.value}
                  type={event.type.value}
                  tijd={event.tijd.value}
                  even={even}
                  show={(show < 0 && showThreshold) || show > -1}
                  imgUrl={url}
                ></EventPanel>
              );
            })}
          </div>

          <EventInfo
            show={eventInfoEnabled}
            title={eventInfo.title}
            info={eventInfo.info}
            datum={eventInfo.datum}
            locatie={eventInfo.locatie}
            type={eventInfo.type}
            tijd={eventInfo.tijd}
            url={eventInfo.url}
          ></EventInfo>

          {/* <div id = 'load' onClick = {()=> {setLimit(!limit); setLoad(!load)}} style = {{zIndex: '500', position: 'flex' , alignSelf: 'flex-end' , justifySelf: 'center' , marginBottom: '3em'}}>LAAD {load? 'MEER': 'MINDER'} EVENEMENTEN</div> */}
          <img
            id="load1"
            src={limit ? laadMeer : laadMinder}
            alt="LAAD MEER EVENEMENTEN"
            onClick={() => {
              setLimit(!limit);
              setLoad(!load);
            }}
          ></img>
        </div>
        {closeGif()}
      </div>
    );
  } else {
    return <div>joooo ff laden..</div>;
  }
}

export default Agenda;

{
  /* <svg width = "500px" viewBox="0 0 500 500" style = {{zIndex: '100', position: 'flex' , alignSelf: 'flex-end' , justifySelf: 'center' , marginBottom: '3em'}}>
    <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" style = {{fill: 'transparent'}} />
    <text width="100">
      <textPath href="#curve">
        LAAD MEER EVENEMENTEN!
      </textPath>
    </text>
  </svg> */
}
