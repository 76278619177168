import React, { useEffect, useState, useRef } from "react";
import "./Info.css";
import List from "./List";
import Button from "./Button";
import FitPanel from "./FitPanel";
import teksten from "../Assets/Tekst/teksten.json";

function Info(props) {
  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max)) - max / 2;
  }

  const ref1 = useRef();
  // const [animateE , setAnimateE] = useState(false)
  // function assignStyle(x){
  //   return( ref1.current.style.transform = "translateX(" + getRandomInt(x) + 'em)' + " rotateZ(" + getRandomInt(x) + 'deg)' + " skew(" + getRandomInt(x*0.2) + 'deg)'

  //     );
  // }

  // useEffect(()=>{
  //   assignStyle(15);
  // },[animateE])

  const [specs, setSpecs] = useState(teksten.specs[props.specs]);

  // console.log(prevProps.specs)

  useEffect((prevProps) => {
    // console.log(specs)
  });
  const listItems = specs.map((spec, index) => <li key={index}>{spec}</li>);
  return (
    <div id="info">
      {/* {setSpecs(teksten.bandbunker.specs)} */}
      {/* <Button id = 'title' isTitle = {props.title}  skew = {props.skew} shift = {props.shift} translate = {props.translate} color= {props.color}></Button> */}
      <FitPanel
        title={props.title}
        skew={props.skew}
        shift={props.shift}
        translate={props.translate}
        color={props.color}
      >
        >
      </FitPanel>

      <ul style={{ backgroundColor: props.color[2] }}>{listItems}</ul>
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref1}
        style={{ backgroundColor: props.resColor }}
        className="reserveer"
      >
        RESERVEER
      </a>
    </div>
  );
}

export default Info;

{
  /* <List specs = {props.specs} bgColor = {props.bgColor} ></List>
      <Button isTitle = {props.title}  skew = {props.skew} shift = {props.shift} translate = {props.translate} color= {props.color}></Button>
      <a id = 'reserveer' href = 'http://www.zero.com'>RESERVEER</a> */
}
