import React, { useEffect, useState, lazy, Suspense } from "react";
import { useSpring, a, config } from "react-spring";
import GifPlayer from "react-gif-player";
import kroew1 from "../Assets/Pics/Kroew.png";
import kroew2 from "../Assets/Pics/kroew/Kroew2.jpg";
import kroew3 from "../Assets/Pics/kroew/Kroew3.jpg";
import kroew4 from "../Assets/Pics/kroew/Kroew4.jpg";
import kroew5 from "../Assets/Pics/kroew/Kroew5.jpg";
import "./Makers copy.css";

import tekst from "../Assets/Tekst/teksten.json";
import TekstPanel from "../Components/TekstPanel";
import Button from "../Components/Button";
import pic from "../Assets/Pics/kroew.jpg";

const kroewGif = { src: require("../Assets/Gifs/kroew-freeze.gif") };

function Kroew(props) {
  const [showGif, setShowGif] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [gifSize, setGifSize] = useState(0.7);
  var [imageLoad, setImageLoad] = useState(0);

  function closeGif() {
    const timer = setTimeout(() => {
      setShowGif(false);
      setShowContent(true);
    }, 3000);
    return () => clearTimeout(timer);
  }

  const contentStyle = useSpring({
    from: { opacity: "0" },
    opacity: showContent ? "1" : "0",
    reset: true,
    delay: 500,
    config: { duration: 1500, clamp: true },
  });
  const gifStyle = useSpring({ transform: showGif ? "scale(1)" : "scale(0)" });

  return (
    <div className="pageWrapper">
      <GifPlayer
        gif={kroewGif.src}
        onLoad={() => setShowGif(true)}
        autoplay={true}
        style={{
          transform: showGif ? "scale(1)" : "scale(0)",
          opacity: showGif ? "1" : "0",
          transition: "all 1s ease",
        }}
      ></GifPlayer>
      <div
        id="makers"
        style={{
          opacity: showContent ? "1" : "0",
          transition: "opacity 1s ease 0.5s",
        }}
      >
        <img
          id="foto11"
          src={kroew1}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <img
          id="foto22"
          src={kroew2}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <div id="tekst">
          <TekstPanel
            title="KROEW"
            tekst2={props.tekst}
            color={["var(--orange)", "var(--sea)"]}
          ></TekstPanel>
        </div>
        <img
          id="foto33"
          src={kroew3}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <img
          id="foto44"
          src={kroew5}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <img
          id="foto55"
          src={kroew4}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <div id="button">
          <Button
            skew={["0deg", "3deg"]}
            shift={["-5px", "-5px"]}
            translate={["3px"]}
            color={["var(--orange)", "var(--sea)"]}
            link="https://forms.gle/4noQDfUKbcrkCgaM9"
          ></Button>
        </div>
      </div>
      {imageLoad === 5 ? closeGif() : "null"}

      {/* <a.div id="kroew" style={contentStyle}>
        <div id="tekst">
          <TekstPanel
            title="KROEW"
            tekst1={tekst.kroew.introTekst}
            tekst2={tekst.kroew.mainTekst}
            color={["var(--pink)", "var(--orange)"]}
          ></TekstPanel>
        </div>
        <img src={pic} alt="jo" onLoad={() => closeGif()} id="kroewFoto"></img>
        <div id="aanmeldButton">
          <Button
            skew={["0deg", "3deg"]}
            shift={["-5px", "-5px"]}
            translate={["3px"]}
            color={["var(--pink)", "var(--orange)"]}
          ></Button>
        </div>
      </a.div> */}
    </div>
  );
}

export default Kroew;
