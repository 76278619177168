import React, { useEffect, useState } from "react";
import tekst from "../Assets/Tekst/teksten.json";
import foto from "../Assets/Pics/kroew.jpg";
import TekstPanel from "../Components/TekstPanel";
import Button from "../Components/Button";
import "./Makers.css";
import GifPlayer from "react-gif-player";
import makersGif from "../Assets/Gifs/makers-freeze.gif";
import makers1 from "../Assets/Pics/Makers1.jpg";
import makers2 from "../Assets/Pics/Makers2.jpg";
import makers3 from "../Assets/Pics/Makers3.jpg";
import makers4 from "../Assets/Pics/Makers4.jpg";
import makers5 from "../Assets/Pics/Makers5.jpg";
import { useSpring, animated } from "react-spring";

function Makers(props) {
  const [showGif, setShowGif] = useState(false);
  const [scaleGif, setScaleGif] = useState(true);
  const [showContent, setShowContent] = useState(false);
  var [imageLoad, setImageLoad] = useState(0);

  function closeGif() {
    const timer = setTimeout(() => {
      setShowGif(false);
      setShowContent(true);
    }, 3000);
    return () => clearTimeout(timer);
  }

  // const contentStyle = useSpring({from: {opacity: '0'},
  //                                 opacity: showContent? '1' : '0',
  //                                 reset: true,
  //                                 delay: 500,
  //                                 config: {duration: 1500}
  //                             })

  return (
    <div className="pageWrapper">
      <GifPlayer
        id="makersGif"
        onLoad={() => {
          setShowGif(true);
          setScaleGif(false);
        }}
        gif={makersGif}
        autoplay={true}
        style={{
          // display: showGif? 'block' : 'none' ,
          transform: scaleGif ? "scale(0.65)" : "scale(0)",
          transition: "all 1.2s ease 3s",
        }}
      ></GifPlayer>
      <div
        id="makers"
        style={{
          opacity: showContent ? "1" : "0",
          transition: "opacity 1s ease 0.5s",
        }}
      >
        <img
          id="foto1"
          src={makers1}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <img
          id="foto2"
          src={makers2}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <div id="tekst">
          <TekstPanel
            title="MAKERS"
            tekst2={props.tekst}
            color={["var(--orange)", "var(--sea)"]}
          ></TekstPanel>
        </div>
        <img
          id="foto3"
          src={makers3}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <img
          id="foto4"
          src={makers5}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <img
          id="foto5"
          src={makers4}
          alt="foto"
          onLoad={() => setImageLoad(imageLoad + 1)}
        ></img>
        <div id="button">
          <Button
            link="https://forms.gle/kgdQVU8LBk5A58AVA"
            skew={["0deg", "3deg"]}
            shift={["-5px", "-5px"]}
            translate={["3px"]}
            color={["var(--orange)", "var(--sea)"]}
          ></Button>
        </div>
      </div>
      {imageLoad === 5 ? closeGif() : "null"}
    </div>
  );
}

export default Makers;
