import React from 'react';
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom"; // for React DOM
import './shader.css';

const shaders = Shaders.create({
    helloBlue: {
      frag: GLSL`
                        precision highp float;
                        varying vec2 uv;
                        uniform float blue;
                        void main() {
                            
                            vec2 st =uv;
                            vec3 color = vec3(0.0);
                        
                            vec2 pos = vec2(0.5)-st;
                        
                            float r = length(pos)*2.0;
                            float a = atan(pos.y,pos.x);
                            r += sin(a*3.)*0.25+0.5;
                            float f = cos(a*3.);
                            // f = abs(cos(a*3.));
                            // f = abs(cos(a*2.5))*.5+.3;
                            // f = abs(cos(a*12.)*sin(a*3.))*.8+.1;
                            // f = smoothstep(-1.,1., cos(a*10.))*0.2+0.2;
                        
                            color = vec3( 1.-smoothstep(f,f+0.1,r) );
                        
                            gl_FragColor = vec4(color, f);
  }`
    }
  });

function HelloBlue(props){
    const { blue } = props.blue;
    return(

        <div id = 'shader'> 
        
            <Node shader={shaders.helloBlue} uniforms={{ blue }} />;
        </div>


    );

}

export default HelloBlue