import React, { useState, useRef, useEffect } from "react";
import ReactFitText from "react-fittext";
import "./EventPanel.css";
import FBLogoOutline from "../Assets/Icons/facebookIconOutline.svg";
import FBLogo from "../Assets/Icons/facebookIcon.svg";

import { setEventInfo } from "../Actions";
import { showEventInfo } from "../Actions";
import { useDispatch } from "react-redux";

function EventPanel(props) {
  const [FBhover, setFBHover] = useState(false);
  const FBsize = FBhover ? "1.5em" : "1em";
  const [animateE, setAnimateE] = useState(false);
  const [counter, setCounter] = useState(0);
  const [panelHover, setPanelHover] = useState(false);

  const dispatch = useDispatch();

  const ref1 = useRef();
  const date = props.datum;
  // console.log("joooo" + date);
  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max)) - max / 2;
  }

  function assignStyle(x) {
    return (ref1.current.style.transform =
      " translateX(0px)" +
      " rotateZ(" +
      getRandomInt(x) +
      "deg)" +
      " skew(" +
      getRandomInt(x * 0.2) +
      "deg)" +
      " scale(1.1)");
  }

  function assignLeave(x) {
    return (ref1.current.style.transform = props.even
      ? "translateX(-5vw) rotateZ(1deg)"
      : "translateX(5vw) rotateZ(-1deg)");
  }

  // useEffect(()=>{
  //   assignStyle(getRandomInt(30));
  // },[animateE])

  return (
    <div
      ref={ref1}
      id="event"
      style={{
        backgroundColor: props.even ? "var(--pink)" : "var(--sea)",
        transform: props.even
          ? "translateX(-5vw) rotateZ(1deg)"
          : "translateX(5vw) rotateZ(-1deg)",
        display: props.show ? "grid" : "none",
      }}
    >
      <div
        id="front"
        onMouseEnter={() => {
          setPanelHover(true);
        }}
        onMouseLeave={() => {
          setPanelHover(false);
        }}
        style={{ backgroundColor: props.even ? "var(--sea)" : "var(--pink)" }}
      >
        <button
          id="datum"
          onClick={() => {
            dispatch(
              setEventInfo({
                title: props.title,
                info: props.info,
                datum: props.datum,
                locatie: props.locatie,
                type: props.type,
                tijd: props.tijd,
                url: props.imgUrl,
              })
            );
            dispatch(showEventInfo(true));
          }}
          style={{
            justifySelf: props.even ? "flex-end" : "flex-start",
            cursor: "pointer",
          }}
        >
          {/* {props.datum.split("-")[0]}&nbsp;{props.datum.split("-")[1]}&nbsp;
          {props.datum.split("-")[2]} */}
          {props.datum}
        </button>

        <ReactFitText compressor={props.title.length * 0.08} maxFontSize={130}>
          <div
            id="titel"
            onMouseLeave={() => {
              assignLeave(getRandomInt(30));
            }}
            onMouseEnter={() => {
              assignStyle(getRandomInt(30));
            }}
            onClick={() => {
              dispatch(
                setEventInfo({
                  title: props.title,
                  info: props.info,
                  datum: props.datum,
                  locatie: props.locatie,
                  type: props.type,
                  tijd: props.tijd,
                  url: props.imgUrl,
                })
              );
              dispatch(showEventInfo(true));
            }}
          >
            {props.title}
          </div>
        </ReactFitText>

        <a
          href={props.fbLink}
          id="FBlink"
          onMouseEnter={() => setFBHover(1)}
          onMouseLeave={() => setFBHover(0)}
          style={{
            backgroundColor: props.even ? "var(--pink)" : "var(--sea)",
            justifySelf: props.even ? "flex-start" : "flex-end",
          }}
        >
          <img id="fbLogo" src={FBLogoOutline} alt="FB"></img>
        </a>
      </div>
    </div>
  );
}

export default EventPanel;

// style = {{transform:  "translateX(" + getRandomInt(10) + 'vw)' + 'rotateZ(' + getRandomInt(15) + 'deg)' , transition: 'all 1s ease'}}
// console.log(window.getComputedStyle(ref1.current).getPropertyValue("grid-row"));

// onMouseLeave = {()=>{ assignLeave(getRandomInt(30));}} onMouseEnter = {()=>{ assignStyle(getRandomInt(30));}}
