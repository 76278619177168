import React from "react";
import ReactFitText from "react-fittext";
import { Textfit } from "react-textfit";
import tekst from "../Assets/Tekst/teksten.json";
import "./ContactPanel.css";

function ContactPanel(props) {
  return (
    <div
      id="contactPanel"
      style={{ transform: "rotateZ(" + props.rotate[0] + ")" }}
    >
      <div id="Gegevens" style={{ backgroundColor: props.color[0] }}>
        <div
          id="title"
          style={{
            order: props.order,
            textAlign: props.order ? "right" : "left",
            transform: "rotateZ(" + props.rotate[1] + ")",
          }}
        >
          <ReactFitText compressor={0.6} maxFontSize={50}>
            <h1>
              {props.title[0]}
              <br />
              {props.title[1]}
            </h1>
          </ReactFitText>
        </div>

        <div id="geg">
          <ReactFitText
            compressor={0.6}
            maxFontSize={window.innerWidth < 1000 ? 10 : 17}
          >
            <h4>
              {props.adres[0]} <br />
              {/* {props.extra ? <br/> : null} */}
              {props.adres[1]}&nbsp;{props.adres[2]}
              <br />
              {props.type === "contact" ? <br /> : null}
              {props.adres[3]}&nbsp;{props.adres[4]}&nbsp;{props.adres[5]}
              <br />
              {props.extra ? <br /> : null}
              {props.adres[6]} <br />
              {props.type === "contact" ? <br /> : null}
              {props.adres[7]}&nbsp;{props.adres[8]}
              {props.factuur ? null : <br />}
              {props.adres[9]}&nbsp;{props.adres[10]}&nbsp;{props.adres[11]}
              {props.factuur ? null : <br />}
            </h4>
          </ReactFitText>
        </div>
      </div>
      <div id="shadow" style={{ backgroundColor: props.color[1] }}></div>
    </div>
  );
}

export default ContactPanel;
