import React from "react";
import "./Button.css";

function Button(props) {
  const bstyle = {
    transform: "skew(" + props.skew + ") translate(" + props.translate + ")",
    zIndex: "1000",
  };

  if (props.isTitle) {
    return (
      <div className="button" style={bstyle}>
        <div className="front" style={{ backgroundColor: props.color[0] }}>
          <h1 style={{ lineHeight: "0.0" }}>{props.isTitle}</h1>
        </div>
        <div
          className="back"
          style={{
            backgroundColor: props.color[1],
            transform: "translate(" + props.shift + ")",
          }}
        ></div>
      </div>
    );
  } else {
    return (
      <div className="button" style={bstyle}>
        <div className="front" style={{ backgroundColor: props.color[0] }}>
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            <h1>MELD JE AAN!</h1>
          </a>
        </div>
        <div className="back" style={{ backgroundColor: props.color[1] }}></div>
      </div>
    );
  }
}

export default Button;
