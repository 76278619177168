import React, { useState, useRef, useEffect } from "react";
import ReactFitText from "react-fittext";
import "./EventInfo.css";

// import ShadertoyReact from "shadertoy-react";
import shadeBG from "../Assets/Shaders/fsBG.js";
import shadeInfo from "../Assets/Shaders/fsInfo.js";
import shadeDeco from "../Assets/Shaders/fsDeco.js";
import blob from "../Assets/Icons/blobx.svg";
import blobClip from "../Assets/Icons/blobxClip.svg";
import blobClose from "../Assets/Icons/blobClose.svg";
import { useSelector, useDispatch } from "react-redux";
import showEventInfo from "../Actions";

function EventInfo(props) {
  const dispatch = useDispatch();
  function createMarkup() {
    return { __html: props.info };
  }

  return (
    <div id="eiWrapper" style={{ display: props.show ? "grid" : "none" }}>
      s
      {/* {props.show? <ShadertoyReact fs  = {shadeInfo} style = {{gridColumn: '1/4', gridRow: '1/4' }}></ShadertoyReact> : ''} */}
      <img
        src={blob}
        alt=""
        style={{
          gridColumn: "1/4",
          gridRow: "1/4",
          alignSelf: "center",
          // zIndex: "2",
        }}
      ></img>
      {/* <figure
        style={{ gridRow: "1/4", gridColumn: "1/4", alignSelf: "center" }}
      > */}
      {/* <svg
        style={{
          position: "fixed",
          transform: "scale(2.5) translate(-25%, 25%)",
          // gridRow: "1",
          // gridColumn: "3",
          alignSelf: "center",
          justifySelf: "center",
          // zIndex: "-10",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="360px"
        height="420px"
        viewBox="0 0 364.707 424.533"
      >
        <defs>
          <clipPath id="user-space" clipPathUnits="userSpaceOnUse">
            <path
              xmlns="http://www.w3.org/2000/svg"
              id="Path_1"
              data-name="Path 1"
              d="M2314.913,1025.067c-60.878,6.244-96.781,31.22-82.732,115.513s-21.854,79.61-18.732,143.61,246.635,252.879,313.757,117.073,78.049-192-31.219-245.074S2375.792,1018.823,2314.913,1025.067Z"
              transform=" scale(1) translate(-2213.256 -1024.739)"
            />
          </clipPath>
        </defs>

        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          // href="https://images.unsplash.com/photo-1508186736123-44a5fcb36f9f?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=7ff4199971d35554c59bbf8e0b229534"
          href={props.url}
          clip-path="url(#user-space)"
        />
      </svg> */}
      {/* </figure> */}
      {/* {console.log({ blobClip })} */}
      {/* <div
        style={{
          gridColumn: "1/4",
          gridRow: "1/4",
          alignSelf: "center",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${props.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          maskImage:
            "url(https://abbeyjfitzgerald.com/wp-content/uploads/2017/10/arrow-01.svg)",
          maskMode: "alpha",
          maskSize: "2vmin",
          // maskRepeat: "no-repeat",
          maskPosition: "center",
        }}
      ></div> */}
      <div id="tekst">
        <h1>{props.title}</h1>
        <div id="pWrap">
          <p dangerouslySetInnerHTML={createMarkup()}></p>
        </div>
        <div id="shortInfo">
          <h5>{props.datum}</h5>
          <h5>{props.type}</h5>
          <h5>{props.tijd}</h5>
          <h5>{props.locatie}</h5>
        </div>
      </div>
      <div id="closeButton">
        {/* {props.show? <ShadertoyReact fs  = {shadeInfo} style = {{gridColumn: '1', gridRow: '1' }}></ShadertoyReact>:''}     */}
        <img
          src={blobClose}
          alt=""
          style={{ gridColumn: "1", gridRow: "1" }}
        ></img>
        <button onClick={() => dispatch(showEventInfo(false))}>X</button>
      </div>
      {/* <div id = 'deco'>
                <ShadertoyReact fs  = {shadeDeco} style = {{gridColumn: '1', gridRow: '1' }}></ShadertoyReact>    
            </div> */}
    </div>
  );
}

export default EventInfo;
