import eventInfoReducer from './eventInfo';
import showEventInfoReducer from './showEventInfo'

import {combineReducers} from 'redux'

export const allReducers = combineReducers({
    setEventInfo : eventInfoReducer,
    showEventInfo : showEventInfoReducer
})

