import "./Navigatie.css";
import React, { useState, useRef } from "react";
import logo from "../Assets/Logo.svg";
import burger from "../Assets/menuIcon.png";
import tekst from "../Assets/Tekst/teksten.json";
function Navigatie(props) {
  const [mode, setMode] = useState(0);
  const [indicator, setIndicator] = useState(false);
  const logoRef = useRef();

  const pages = tekst.navigatie.pages;
  // console.log(pages);

  const pagesx = pages.map((page) => (
    <div className="portal">
      <img ref={logoRef} src={logo} alt="logo"></img>
      <h2
        onClick={() => {
          logoRef.current.style.display = "inline";
          logoRef.current.style.width = "1.36em";
          logoRef.current.style.transform = "rotateZ(1080deg)";
        }}
      >
        {page}
      </h2>
    </div>
  ));

  return (
    <div id="navigatie">
      <img id="logo" src={logo} alt="logo"></img>
      <ul>
        {pagesx}

        {/* <li>AGENDA</li>
                <li>KROEW</li>
                <li>MAKERS</li>
                <li>DE BANDBUNKER</li>
                <li>DE KABINE</li>
                <li>OVER ONS</li>
                <li>CONTACT</li> */}
      </ul>
      <img id="menu" src={burger} alt="menu"></img>
    </div>
  );
}

export default Navigatie;
