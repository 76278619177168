import React, { useState } from "react";
import { a, useSpring } from "react-spring";
import { NavLink } from "react-router-dom";
import logo from "../Assets/Logo.svg";
import "./menuItem.css";
import { css, jsx } from "@emotion/core";

function Menuitem(props) {
  const [sw, setSW] = useState(window.innerWidth);
  window.addEventListener("resize", function () {
    // console.log(window.innerWidth);
    setSW(window.innerWidth);
  });

  // const props = useSpring({opacity: 1, color: 'red'})
  const [indicator, setIndicator] = useState(false);
  const stylo = useSpring({
    opacity: indicator ? 1 : 0,
    // maxWidth: indicator? '1.5em' : '0em',
    // transform: indicator? 'rotateZ(360deg)' : 'rotateZ(180deg)'
  });
  const styx = useSpring({ opacity: indicator ? 1 : 0 });

  function createMarkup() {
    return { __html: props.page.name };
  }
  return (
    <a.div id="menuItem">
      <NavLink
        to={props.page.path}
        isActive={(match, location) => {
          if (!match) {
            return setIndicator(false);
          }
          setIndicator(true);
        }}
        style={{
          display: "grid",
          minHeight: "65%",
          alignItems: "flex-end",
          // alignContent: 'center',
          // backgroundColor: 'yellow'
        }}
      >
        {/* {console.log(indicator)} */}
        <img
          id="indicator"
          src={logo}
          alt="logo"
          style={{
            transition: "all 1s ease",
            transitionDelay: "0.8s",
            //   backgroundColor: 'green',
            maxWidth: indicator ? "1em" : "0em",
            display: sw > 1000 ? "" : "none",
            transform: indicator ? "rotateZ(360deg)" : "rotateZ(0deg)",
          }}
        ></img>
        <div
          id="unit"
          style={{ paddingTop: "1em", cursor: "pointer" }}
          dangerouslySetInnerHTML={createMarkup()}
        ></div>
      </NavLink>
    </a.div>
  );
}

export default Menuitem;
