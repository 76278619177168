import React, { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useTransition, a, config } from "react-spring";
import "./NavigatieBar.css";
import Menuitem from "./menuItem";
import MenuitemMobiel from "./menuItemMobiel";
import logo from "../Assets/Logo.svg";

import hamburger from "../Assets/Icons/hamburger.svg";
import cross from "../Assets/SVG/cross.svg";
import Menumobiel from "./Menumobiel";
import Background from "./bg";
function NavigatieBar(props) {
  const location = useLocation();
  const [mode, setMode] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const transitions = useTransition(mode, null, {
    from: { margin: "0px", transform: "translateX(100%)" },
    enter: { transform: "translateX(0)" },
    leave: { transform: "translateX(-100%)" },
    config: config.wobbly,
    duration: 2000,
  });

  const pages = [
    { name: "AGENDA", path: "./agenda" },
    { name: "KROEW", path: "./kroew" },
    { name: "MAKERS", path: "./makers" },
    { name: "DE&nbsp;BANDBUNKER", path: "./bandbunker" },
    { name: "DE&nbsp;KABINE", path: "./kabine" },
    { name: "OVER&nbsp;ONS", path: "./over ons" },
    { name: "CONTACT", path: "./contact" },
  ];
  // const menuItems = pages.map((page) => <Menuitem mode = {props.mode} key = {page.name} page = {page}></Menuitem> )
  const menuItems = pages.map((page) => (
    <MenuitemMobiel
      mode={props.mode}
      key={page.name}
      page={page}
    ></MenuitemMobiel>
  ));
  let prev = props.prev;
  return (
    <div id="navWrapper">
      <div
        onClick={() => setShowMenu(false)}
        id="menuWrapper"
        style={{ display: showMenu ? "flex" : "none" }}
      >
        <Menumobiel ani={showMenu}></Menumobiel>
      </div>
      <div id="navigatieBar">
        <img id="logo" src={logo} alt="logo"></img>
        {/* <NavLink
          onClick={() => {
            prev.push(location.pathname);
          }}
          to={location.pathname === "/menu" ? prev.location.pathname : "/menu"}
          style={{
            width: "100%",
            height: "100%",

            display: "flex",
            alignItems: "center",
          }}
        >
          {console.log(prev.location.pathname)} */}

        <img
          id="hamburger"
          src={showMenu ? cross : hamburger}
          alt="menu"
          onClick={() => {
            setMode(!mode);
            setShowMenu(!showMenu);
          }}
        ></img>
        {/* </NavLink> */}

        {/* { transitions.map(({ item, key, props }) => item 
            ? <a.div id= 'menu' style = {props} onClick = {()=> setMode(!mode)}>
                <a.ul id = "menuList" style = {{display: props.display ? '' : props.mode < 1000 ? 'none' : ''}}>
                    {menuItems}
                </a.ul>
            </a.div> 
            : <a.div id= 'indi' style = {props} >
                {location.pathname.split('/')[1]}
                </a.div>
            )} */}
        <a.div id="indi" style={props}>
          {showMenu ? "menu" : location.pathname.split("/")[1]}
        </a.div>
      </div>
    </div>
  );
}

export default NavigatieBar;
