import React from "react";
import "./TekstPanel.css";

function TekstPanel(props) {
  function createMarkup() {
    return { __html: props.tekst2 };
  }
  if (props.tekst1) {
    return (
      <div className="tekstPanel">
        <div
          className="shadow "
          style={{ backgroundColor: props.color[0] }}
        ></div>
        <div
          className="front "
          style={{ backgroundColor: props.color[1] }}
          dangerouslySetInnerHTML={createMarkup()}
        >
          {/* <h1>{props.title}</h1> */}
          {/* <p dangerouslySetInnerHTML={createMarkup()}></p> */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="tekstPanel">
        <div
          className=" shadow "
          style={{ backgroundColor: props.color[0] }}
        ></div>
        <div
          className=" front "
          style={{ backgroundColor: props.color[1] }}
          dangerouslySetInnerHTML={createMarkup()}
        >
          {/* <h1>{props.title}</h1> */}
          {/* <p dangerouslySetInnerHTML={createMarkup()}></p> */}
        </div>
      </div>
    );
  }
}

export default TekstPanel;
