import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "./bg.css";

import agendaBG from "../Assets/Pics/ruis.jpeg";
import pageBG from "../Assets/Pics/ruispage.jpg";

function Background() {
  let location = useLocation().pathname;

  function bgColor(_loc) {
    switch (_loc) {
      case "/agenda":
        return "white";
      case "/kroew":
        return "var(--sea)";
      case "/makers":
        return "var(--pink)";
      case "/bandbunker":
        return "var(--sea)";
      case "/kabine":
        return "var(--purple)";
      case "/over ons":
        return "var(--orange)";
      case "/contact":
        return "var(--orange)";

      default:
        return "white";
    }
  }

  var loc = useLocation().pathname;
  // console.log(loc);
  return (
    <div
      id="bgGlobal"
      style={{
        backgroundImage:
          loc === "/agenda" ? "url(" + agendaBG + ")" : "url(" + pageBG + ")",
        backgroundColor: bgColor(location),
      }}
    >
      {/* <div/> */}
    </div>
  );
}

export default Background;
