import React, { useState } from 'react';
import {useSpring , animated} from 'react-spring'
import ReactDOM from 'react-dom'
import './NavBarDesktopX.css'
import logo from '../Assets/Logo.svg'
import hamburger from '../Assets/Icons/hamburger.svg'
import {useLocation , Link} from 'react-router-dom'
import Menulist from './Menulist';
import MenulistMobile from './MenulistMobile';
import NavBar from './NavBar';
import fbLogo from '../Assets/Icons/fb_logo.svg'
import instaLogo from '../Assets/Icons/insta_logo.svg'

export function Menumobile(props){
        
    return(
        <div>
            <Menulist mode = {props.sw}></Menulist>
            <div id = 'page' style = {{display: props.clicked? 'none' : 'flex'}}><h3>{props.loc.pathname.split("/")[1]}</h3></div> 
        </div>
    );
}

function NavBarDesktopX(props){
    // const [sw, setSW] = useState(window.innerWidth);
    // window.addEventListener("resize", function() {
    //     console.log(window.innerWidth);
    //     setSW(window.innerWidth)
    // });

    const [state, toggle] = useState(true)
    const { x } = useSpring({ from: { x: 1 }, x: state ? 1 : 0,  config: { duration: 1200 } })
    const { y } = useSpring({ from: { y: 1 }, y: state ? 1 : 0, config: { duration: 2000 } })
    
    
    const [menuClicked , setMenuClicked] = useState(false);
    
    

    return(
        
        <animated.div   className = 'navBarDesktop' onClick={() => toggle(!state)} 
                        style={{opacity: x.interpolate({ range: [0, 0.2 , 0.8 , 1], output: [1, 0, 0 , 1] })}}
                        >
           
            <img id = 'logo' src = {logo} alt = 'PE'></img>  
            <Menulist></Menulist>
            <img id = 'hamburger' src = {hamburger} alt = 'menu' onClick = {()=>setMenuClicked(true)}></img>
            <div id = 'footer'>
                <a href = 'https://www.facebook.com/perexpressie/'><img src = {fbLogo} alt = 'fb'></img></a>
                <a href = 'https://www.instagram.com/perexpressie/?hl=nl'><img src = {instaLogo} alt = 'insta'></img></a>
            </div>
        </animated.div>
    )

}

export default NavBarDesktopX;