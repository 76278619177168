import React, { useEffect, useState } from "react";
import tekst from "../Assets/Tekst/teksten.json";
import foto from "../Assets/Pics/kroew.jpg";
import TekstPanel from "../Components/TekstPanel";
import Info from "../Components/Info";
import "./Kabine.css";
import GifPlayer from "react-gif-player";
import kroewGif from "../Assets/Gifs/06-DEKABINE.gif";

function Kabine(props) {
  const [showGif, setShowGif] = useState(false);
  const [showContent, setShowContent] = useState(false);
  var [imageLoad, setImageLoad] = useState(0);

  function closeGif() {
    const timer = setTimeout(() => {
      setShowGif(false);
      setShowContent(true);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div className="pageWrapper">
      <GifPlayer
        gif={kroewGif}
        onLoad={() => setShowGif(true)}
        autoplay={true}
        style={{
          opacity: showGif ? "100%" : "0%",
          transition: "all 0.5s ease",
          transform: showGif ? "scale(1)" : "scale(0)",
        }}
      ></GifPlayer>
      <div
        className="kabine"
        style={{
          opacity: showContent ? "1" : "0",
          transition: "opacity 1s ease 0.5s",
        }}
      >
        <div id="tekst">
          <TekstPanel
            title="DE KABINE"
            tekst2={props.tekst}
            color={["var(--orange)", "var(--green)"]}
          ></TekstPanel>
        </div>

        <iframe
          // id="omni2"
          style={{
            border: "2px solid white",
            minHeight: "30vh",
            gridRow: "2",
            gridColumn: "1/-1",
          }}
          onLoad={() => setImageLoad(imageLoad + 1)}
          title="DJ ruimte"
          height="100%"
          width="80%"
          allowfullscreen="true"
          src="https://momento360.com/e/u/6363418afdeb48c8b0d31798d0859932?utm_campaign=embed&utm_source=other&heading=-24.748157114085487&pitch=-17.421365862284006&field-of-view=75"
        ></iframe>
      </div>
      {imageLoad === 1 ? closeGif() : "null"}
    </div>
  );
}

export default Kabine;

{
  /* <a href = 'https://www.color.ink'>RESERVEER</a> */
}
{
  /* <Info specs = "ruimte1" bgColor = '#4CA9AF' title = 'GEAR' skew = {['0deg' , '-3deg']} shift = {['-5px' , '-5px']} translate = {['3px']} color= {['#DAA0C8','#4FA2D9']}></Info> */
}
